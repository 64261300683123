import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import i18n from '../../../../../i18n';

const TariffOptimizedChart = (props) => {
  const { chartData, tariffPriceLimit } = props;
  const options = {
    title: { text: '' },
    chart: {
      backgroundColor: 'transparent',
      height: 200
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      gridLineColor: '#FFFFFF1A',
      lineColor: '#FFFFFF1A',
      gridLineWidth: 1,
      tickLength: 0,
      tickInterval: 4 * 3600000,
      labels: {
        style: {
          color: '#ffffff80',
          fontSize: '10px',
          fontWeight: '500'
        }
      },
      plotLines: [
        {
          color: 'rgba(255, 255, 255, 0.25)',
          width: 3,
          value: new Date().getTime()
        }
      ]
    },
    yAxis: {
      title: null,
      gridLineColor: '#FFFFFF1A',
      lineColor: '#FFFFFF1A',
      tickAmount: 5,
      min: 0,
      labels: {
        style: {
          color: '#ffffff80',
          fontSize: '10px',
          fontWeight: '500'
        },
        formatter(data) {
          return data.value ? data.value.toFixed(2) : data.value;
        }
      },
      plotLines: [
        {
          color: 'rgba(163, 35, 142, 0.15)',
          width: 4,
          value: tariffPriceLimit
        }
      ]
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle'
        },
        enableMouseTracking: false
      }
    },
    legend: {
      enabled: false
    },
    series: [
      {
        data: chartData,
        zoneAxis: 'x',
        zIndex: 2,
        zones: [
          {
            value: new Date().getTime(),
            color: '#818285'
          },
          {
            color: 'transparent'
          }
        ]
      },
      {
        data: chartData,
        zoneAxis: 'y',
        zIndex: 1,
        zones: [
          {
            value: tariffPriceLimit,
            color: '#A3238E'
          },
          {
            color: '#DCDCDC'
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    lang: {
      noData: i18n.t('chartsNoData'),
      loading: i18n.t('load')
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
TariffOptimizedChart.propTypes = {
  chartData: PropTypes.instanceOf(Object).isRequired,
  tariffPriceLimit: PropTypes.number.isRequired
};

export default TariffOptimizedChart;
