import React, { useEffect, useRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import SemiTabs from '../Monitoring/components/SemiTabs';
import { Breadcrumbs, TopBar } from '../../components';
import { Switcher, RadioButtons, Checkbox } from '../../components/ReduxFormFields';

import { sendRequest } from './slice';

import { getSignInUserEmailNotificationSelector, getSignInUserRoleTypeSelector } from '../../redux-store/selectors/signIn';
import { getMonitoringNotificationsNotificationsValueSelector } from '../../redux-store/selectors/monitoringNotifications';
import { formValuesSelector } from '../../redux-store/selectors/form';
import { visibleWebSelector } from '../Monitoring/selectors';
import i18n from '../../i18n';

import './index.scss';

const frequencyVariants = () => [
  {
    label: <span className="white dataSMID">{i18n.t('daily')}</span>,
    value: 'daily'
  },
  {
    label: <span className="white dataSMID">{i18n.t('weekly')}</span>,
    value: 'weekly'
  },
  {
    label: <span className="white dataSMID">{i18n.t('monthly')}</span>,
    value: 'monthly'
  }
];

const receiveAssignedErrorsVariants = () => [
  {
    label: <span className="white dataSMID">{i18n.t('allErrors')}</span>,
    value: false
  },
  {
    label: <span className="white dataSMID">{i18n.t('onlyErrors')}</span>,
    value: true
  }
];

/**
 * Renders NotificationMonitoring page
 * @memberof module:NotificationMonitoring
 */
const MonitoringNotifications = () => {
  const dispatch = useDispatch();
  const hardFixForSubmit = useRef(false);

  const visibleWeb = useSelector(visibleWebSelector);
  const formValues = useSelector(formValuesSelector);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);

  useEffect(() => {
    if (Object.keys(formValues).length && hardFixForSubmit.current) {
      dispatch(sendRequest({ data: formValues }));
    }
    hardFixForSubmit.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.everyNewError, formValues.frequency, formValues.isAllowed, formValues.receiveAssignedErrors]);

  return (
    <div className="main-table-container">
      <TopBar
        updateButton
        showNavigation
        visibleWeb={visibleWeb}
      />
      <div className="container-fluid">
        <Breadcrumbs crumbs={[{ name: i18n.t('monitoring') }]} />

        <div className="nav-monitoring">
          <SemiTabs />
        </div>
        <form id="monitoringNotifications">
          <div className="notification-switch-container">
            <div className="notification-switch-field m--margin-top-50">
              <h6 className="set-prof-head m--margin-right-20">{i18n.t('emailNotification')}</h6>
              <Field
                name="isAllowed"
                component={Switcher}
                props={{
                  width: 50,
                  height: 25,
                  onColor: '#A3238E',
                  className: 'switcher'
                }}
              />
            </div>
          </div>
          <div className="notification-frequency-container m--margin-top-50 d-flex flex-column gap-4">
            <h6 className="freq-text">{i18n.t('frequencyOfSendingEmail')}</h6>
            <Field
              name="frequency"
              component={RadioButtons}
              props={{
                variants: frequencyVariants()
              }}
            />
            {['oem', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'oem_employee', 'oem_employee_read_only', 'pv_installer_employee_read_install'].includes(myRoleType) ? (
              <div className="every-new-error">
                <Field
                  type="checkbox"
                  name="everyNewError"
                  component={Checkbox}
                  labelClass="every-new-error-label"
                  label={i18n.t('everyNewError')}
                />
              </div>
            ) : null}
          </div>
          <div className="notification-type-error-container m--margin-top-60 d-flex flex-column gap-4">
            <h6 className="freq-text">{i18n.t('whatErrorsShouldBeReceived')}</h6>
            <Field
              name="receiveAssignedErrors"
              component={RadioButtons}
              props={{
                variants: receiveAssignedErrorsVariants()
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const MonitoringNotificationsForm = reduxForm({
  form: 'notificationForm'
})(MonitoringNotifications);

const MonitoringNotificationsWrapper = () => {
  const defaultUserNotification = useSelector(getSignInUserEmailNotificationSelector);
  const responseNotification = useSelector(getMonitoringNotificationsNotificationsValueSelector);
  const notificationObject = Object.keys(responseNotification).length ? responseNotification : defaultUserNotification;
  const { isAllowed, frequency, everyNewError, receiveAssignedErrors } = notificationObject;

  return (
    <MonitoringNotificationsForm initialValues={{
      isAllowed: isAllowed ?? false,
      frequency: frequency ?? 'monthly',
      everyNewError: everyNewError ?? false,
      receiveAssignedErrors: receiveAssignedErrors ?? false
    }}
    />
  );
};

export default MonitoringNotificationsWrapper;
