const replace = {
  changeSMID: 'Wijzig de Rennergy ID',
  reqSMID: 'Rennergy ID *',
  SMID: 'Rennergy ID',
  SMIDTolltip: 'De ID is een unieke identificatie van uw apparaat. Deze ID vind je op het toestel onder de QR-code.',
  enableSmId: 'Rennergy ID',
  smidLabel: 'Rennergy ID',
  confirmDeletingDesc: 'Bevestig het verwijderen van alle gegevens van deze gateway door de Rennergy ID in te voeren. Eenmaal bevestigd, kan dit proces niet meer worden teruggedraaid.',
  confirmDeletingError: 'De ID komt niet overeen met de Rennergy ID. De gateway wordt niet verwijderd.'
};

export default replace;
