import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DEVICE_LIMIT_REACHED_POPUP, openModalWindow, UPGRADE_TO_UNLOCK_POPUP } from '../../../containers/ModalWindow/slice';

const CustomSelectOption = (props) => {
  const { innerProps, isDisabled, data, selectProps } = props;
  const dispatch = useDispatch();
  const openUpgradePopup = () => dispatch(openModalWindow({ modalID: (data?.value === 'V2X' || selectProps.name === 'data.batteryMode') ? UPGRADE_TO_UNLOCK_POPUP : DEVICE_LIMIT_REACHED_POPUP }));

  return (
    <div
      {...innerProps}
      className="react-select__option"
      style={{ opacity: isDisabled ? 0.55 : 1 }}
      onClick={data.onClick || (data.isOpenUpgradePopup ? openUpgradePopup : innerProps.onClick)}
    >
      {data.label}
    </div>
  );
};

CustomSelectOption.propTypes = {
  innerProps: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  selectProps: PropTypes.instanceOf(Object).isRequired,
  isDisabled: PropTypes.bool
};

export default CustomSelectOption;
